@import "./_sass-essentials/essentials.scss";

@mixin touch-css() {
  .card-wrap {
    display: flex;
    align-items: center;
    padding: 15px;
  }

  .visible-content {
    display: flex;
    flex: 0 0 60px;

    .name {
      display: none;
    }
  }

  .slide-in-content {
    padding: 0 0 0 15px;
    flex: 1;
    align-items: center;

    .name {
      color: $c-base-txt;
    }

    .more-btn {
      background-color: $c-brand-secundary;
    }
  }
}

@mixin no-touch-css() {
  .more-btn {
    &:hover {
      background-color: $c-brand-primary;

      svg {
        fill: $c-brand-secundary;
      }
    }
  }

  .card-wrap {
    position: relative;
    overflow: hidden;
    padding: 0;
  }

  .visible-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;

    .field--name-field-services-icon {
      margin-bottom: 15px;
    }
  }

  .slide-in-content {
    color: $c-white;
    padding: 25px 25px 75px 25px;
    background-color: $c-brand-secundary;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 100%;
    left: 0;
    @include transition;
    //display: none;
    //top: 0;

    .more-btn {
      position: absolute;
      right: 25px;
      bottom: 25px;
    }
  }

  &:hover {
    .slide-in-content {
      top: 0;
    }
  }
}

.node--services--teaser {
  width: 100%;
  height: 100%;
  background-color: $c-brand-primary;

  .name {
    text-transform: none;
    font-weight: $fw-bold;
    color: $c-brand-secundary;
    margin: 0;
  }

  .field--name-field-services-icon {
    svg {
      display: block;
      max-width: 60px;
      max-height: 73px;

      .bg, .bg-inner {
        display: none;
      }

      .border {
        display: initial;
        fill: $c-brand-secundary;
      }
    }
  }

  .more-btn {
    width: 40px;
    height: 40px;
    background-color: $c-white;
    display: flex;
    justify-content: center;
    align-items: center;
    @include transition;

    svg {
      width: 100%;
      height: auto;
      max-width: 20px;
      max-height: 20px;
      @include transition;
      fill: $c-brand-secundary;
    }

    &:hover {
      background-color: $c-brand-secundary;

      svg {
        fill: $c-brand-primary;
      }
    }
  }

  .card-wrap {
    width: 100%;
    height: 100%;
    display: block;
    color: $c-base-txt;
    font-size: 0.8em;
  }

  .slide-in-content {
    display: flex;

    .name {
      color: $c-white;
    }

    .content-inner {
      flex: 1;
    }

    .more-btn {
      flex: 0 0 40px;
    }
  }

  // CSS mods for devices using touch.
  html.touchevents & {
    @include touch-css;
  }

  // CSS mods for devices using pointing devices.
  html.no-touchevents & {
    @include no-touch-css;
  }
}
