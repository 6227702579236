.node--services--teaser {
  width: 100%;
  height: 100%;
  background-color: #FFD400;
}

.node--services--teaser .name {
  text-transform: none;
  font-weight: 700;
  color: #29363E;
  margin: 0;
}

.node--services--teaser .field--name-field-services-icon svg {
  display: block;
  max-width: 60px;
  max-height: 73px;
}

.node--services--teaser .field--name-field-services-icon svg .bg, .node--services--teaser .field--name-field-services-icon svg .bg-inner {
  display: none;
}

.node--services--teaser .field--name-field-services-icon svg .border {
  display: initial;
  fill: #29363E;
}

.node--services--teaser .more-btn {
  width: 40px;
  height: 40px;
  background-color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 250ms ease;
}

.node--services--teaser .more-btn svg {
  width: 100%;
  height: auto;
  max-width: 20px;
  max-height: 20px;
  transition: all 250ms ease;
  fill: #29363E;
}

.node--services--teaser .more-btn:hover {
  background-color: #29363E;
}

.node--services--teaser .more-btn:hover svg {
  fill: #FFD400;
}

.node--services--teaser .card-wrap {
  width: 100%;
  height: 100%;
  display: block;
  color: #29363E;
  font-size: 0.8em;
}

.node--services--teaser .slide-in-content {
  display: flex;
}

.node--services--teaser .slide-in-content .name {
  color: #FFF;
}

.node--services--teaser .slide-in-content .content-inner {
  flex: 1;
}

.node--services--teaser .slide-in-content .more-btn {
  flex: 0 0 40px;
}

html.touchevents .node--services--teaser .card-wrap {
  display: flex;
  align-items: center;
  padding: 15px;
}

html.touchevents .node--services--teaser .visible-content {
  display: flex;
  flex: 0 0 60px;
}

html.touchevents .node--services--teaser .visible-content .name {
  display: none;
}

html.touchevents .node--services--teaser .slide-in-content {
  padding: 0 0 0 15px;
  flex: 1;
  align-items: center;
}

html.touchevents .node--services--teaser .slide-in-content .name {
  color: #29363E;
}

html.touchevents .node--services--teaser .slide-in-content .more-btn {
  background-color: #29363E;
}

html.no-touchevents .node--services--teaser .more-btn:hover {
  background-color: #FFD400;
}

html.no-touchevents .node--services--teaser .more-btn:hover svg {
  fill: #29363E;
}

html.no-touchevents .node--services--teaser .card-wrap {
  position: relative;
  overflow: hidden;
  padding: 0;
}

html.no-touchevents .node--services--teaser .visible-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

html.no-touchevents .node--services--teaser .visible-content .field--name-field-services-icon {
  margin-bottom: 15px;
}

html.no-touchevents .node--services--teaser .slide-in-content {
  color: #FFF;
  padding: 25px 25px 75px 25px;
  background-color: #29363E;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 100%;
  left: 0;
  transition: all 250ms ease;
}

html.no-touchevents .node--services--teaser .slide-in-content .more-btn {
  position: absolute;
  right: 25px;
  bottom: 25px;
}

html.no-touchevents .node--services--teaser:hover .slide-in-content {
  top: 0;
}
